var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{staticClass:"outersize-confirm-type-three mr-2",attrs:{"title":"Workflow Expiration & Reminders","visible":_vm.docSettingsVisible,"width":"100%","top":"25px","before-close":_vm.close},on:{"update:visible":function($event){_vm.docSettingsVisible=$event}}},[_c('el-form',{ref:"form",attrs:{"model":_vm.settings}},[_c('el-row',{staticClass:"sidemenu"},[_c('el-col',{attrs:{"span":24}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"py-3 px-2"},[_c('h4',{staticClass:"fs-6 text-dark fw-medium"},[_vm._v("Secure E-Sign")]),_c('el-switch',{attrs:{"active-value":true,"inactive-value":false,"active-text":"Secure E-Sign"},model:{value:(_vm.settings.secure_e_sign.allow_document_e_sign),callback:function ($$v) {_vm.$set(_vm.settings.secure_e_sign, "allow_document_e_sign", $$v)},expression:"settings.secure_e_sign.allow_document_e_sign"}})],1),(
                  _vm.settings.secure_e_sign.allow_document_e_sign
                )?_c('div',{staticClass:"mb-1"},[_c('el-checkbox-group',{model:{value:(_vm.settings.secure_e_sign.notify_through),callback:function ($$v) {_vm.$set(_vm.settings.secure_e_sign, "notify_through", $$v)},expression:"settings.secure_e_sign.notify_through"}},[_c('el-checkbox',{attrs:{"label":"Send_Email"}},[_vm._v("Send Email")]),_c('el-checkbox',{attrs:{"label":"Send_Sms"}},[_vm._v("Send Sms")])],1),(_vm.sendError && !_vm.settings.secure_e_sign.notify_through.length)?_c('span',{staticStyle:{"color":"red"}},[_vm._v(" Please Select Atleast One Option ")]):_vm._e()],1):_vm._e()]),_c('el-divider',{staticClass:"m-0"}),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"pb-3 px-2"},[_c('h4',{staticClass:"fs-6 text-dark fw-medium"},[_vm._v("Update entity data")]),_c('p',[_vm._v(" If you used entity fields on document. You can update the entity data by that document data. ")]),_c('div',{staticClass:"d-flex w-100 align-items-center"},[_c('el-radio-group',{model:{value:(_vm.settings.update_entity_data),callback:function ($$v) {_vm.$set(_vm.settings, "update_entity_data", $$v)},expression:"settings.update_entity_data"}},[_c('el-radio',{attrs:{"label":0}},[_vm._v("Update after document completion")]),_c('el-radio',{attrs:{"label":1}},[_vm._v("Update after user finishes the document")]),_c('el-radio',{attrs:{"label":2}},[_vm._v("Never update")])],1)],1)])]),_c('el-divider',{staticClass:"m-0"}),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"pb-3 px-2"},[_c('h4',{staticClass:"fs-6 text-dark fw-medium"},[_vm._v("Document Generation Settings")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('el-checkbox',{model:{value:(
                    _vm.settings.document_generation_settings.add_document_generated_id
                  ),callback:function ($$v) {_vm.$set(_vm.settings.document_generation_settings, "add_document_generated_id", $$v)},expression:"\n                    settings.document_generation_settings.add_document_generated_id\n                  "}},[_vm._v(" Add eSigns generated id")]),_c('el-checkbox',{model:{value:(
                    _vm.settings.document_generation_settings.add_page_number
                  ),callback:function ($$v) {_vm.$set(_vm.settings.document_generation_settings, "add_page_number", $$v)},expression:"\n                    settings.document_generation_settings.add_page_number\n                  "}},[_vm._v(" Add page numbers")])],1)])]),_c('el-divider',{staticClass:"m-0"}),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"pb-3 px-2"},[_c('h4',{staticClass:"fs-6 text-dark fw-medium"},[_vm._v("Send attachment")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('el-checkbox',{model:{value:(_vm.settings.email_attachement.attach_pdf),callback:function ($$v) {_vm.$set(_vm.settings.email_attachement, "attach_pdf", $$v)},expression:"settings.email_attachement.attach_pdf"}}),_c('div',{staticClass:"px-2 word-break-normal"},[_vm._v(" "+_vm._s("Send completed document as attachment in mail after document completed.")+" ")])],1)])]),_c('el-divider',{staticClass:"m-0"}),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"pb-3 px-2"},[_c('h4',{staticClass:"fs-6 text-dark fw-medium"},[_vm._v("Expiration Settings")]),_c('p',[_vm._v(" Set the expiry date for your document validity so the system does it for you automatically. ")]),_c('div',{staticClass:"d-flex w-100 align-items-center"},[_c('div',{staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(_vm.getIsMobile ? "Expire sent docs in" : "Expire sent documents in")+" ")]),_c('div',{staticClass:"pl-2",staticStyle:{"max-width":"180px"}},[_c('el-input',{attrs:{"placeholder":"0","min":"1","type":"number","size":"medium"},on:{"input":_vm.changeExpireDate},model:{value:(
                      _vm.settings.expiration_settings.expire_documents_in_days
                    ),callback:function ($$v) {_vm.$set(_vm.settings.expiration_settings, "expire_documents_in_days", $$v)},expression:"\n                      settings.expiration_settings.expire_documents_in_days\n                    "}},[_c('template',{slot:"append"},[_c('i',{staticClass:"el-icon-date"}),_vm._v(" days")])],2)],1)]),_c('div',{staticClass:"expiration-reminder-check mt-1"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"px-2 word-break-normal"},[_vm._v("Expire Date")]),_c('el-date-picker',{attrs:{"disabled":""},model:{value:(
                      _vm.settings.expiration_settings.document_expired_date
                    ),callback:function ($$v) {_vm.$set(_vm.settings.expiration_settings, "document_expired_date", $$v)},expression:"\n                      settings.expiration_settings.document_expired_date\n                    "}})],1)]),_c('div',{staticClass:"expiration-reminder-check mt-1"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('el-checkbox',{model:{value:(
                      _vm.settings.expiration_settings
                        .send_expiration_reminder_status
                    ),callback:function ($$v) {_vm.$set(_vm.settings.expiration_settings
                        , "send_expiration_reminder_status", $$v)},expression:"\n                      settings.expiration_settings\n                        .send_expiration_reminder_status\n                    "}}),_c('div',{staticClass:"px-2 word-break-normal"},[_vm._v(" "+_vm._s(_vm.getIsMobile ? "Remind in" : "Send First Reminder")+" ")]),_c('el-input-number',{attrs:{"disabled":!_vm.settings.expiration_settings
                        .send_expiration_reminder_status,"min":1,"max":_vm.settings.expiration_settings.expire_documents_in_days},model:{value:(
                      _vm.settings.expiration_settings.send_first_reminder_in_days
                    ),callback:function ($$v) {_vm.$set(_vm.settings.expiration_settings, "send_first_reminder_in_days", $$v)},expression:"\n                      settings.expiration_settings.send_first_reminder_in_days\n                    "}}),_c('div',{staticClass:"pl-1"},[_vm._v("Before Expiration")])],1)]),_c('div',{staticClass:"expiration-reminder-check mt-1"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('el-checkbox',{model:{value:(
                      _vm.settings.expiration_settings
                        .repeat_expiration_reminder_status
                    ),callback:function ($$v) {_vm.$set(_vm.settings.expiration_settings
                        , "repeat_expiration_reminder_status", $$v)},expression:"\n                      settings.expiration_settings\n                        .repeat_expiration_reminder_status\n                    "}}),_c('div',{staticClass:"px-2 word-break-normal"},[_vm._v(" "+_vm._s(_vm.getIsMobile ? "Repeat" : "Repeat Reminder")+" ")]),_c('el-input-number',{attrs:{"disabled":!_vm.settings.expiration_settings
                        .repeat_expiration_reminder_status,"min":1,"max":_vm.settings.expiration_settings.expire_documents_in_days},model:{value:(
                      _vm.settings.expiration_settings.repeat_expiration_reminder
                    ),callback:function ($$v) {_vm.$set(_vm.settings.expiration_settings, "repeat_expiration_reminder", $$v)},expression:"\n                      settings.expiration_settings.repeat_expiration_reminder\n                    "}}),_c('div',{staticClass:"pl-1"},[_vm._v("in Days")])],1)])])]),_c('el-divider',{staticClass:"m-0"}),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"py-3 px-2"},[_c('h4',{staticClass:"fs-6 text-dark fw-medium"},[_vm._v(" Auto Reminders Settings ")]),_c('p',[_vm._v(" Automatically send email reminders to signers regarding the incompletion of document. ")]),_c('div',{staticClass:"d-flex w-100 align-items-center mb-1"},[_c('el-checkbox',{model:{value:(
                    _vm.settings.auto_remainders_settings.first_reminder_status
                  ),callback:function ($$v) {_vm.$set(_vm.settings.auto_remainders_settings, "first_reminder_status", $$v)},expression:"\n                    settings.auto_remainders_settings.first_reminder_status\n                  "}}),_c('div',{staticClass:"px-2 word-break-normal",staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(_vm.getIsMobile ? "Remind in" : "Send First Reminder in")+" ")]),_c('div',{staticClass:"pl-2",staticStyle:{"max-width":"180px"}},[_c('el-input',{attrs:{"placeholder":"0","type":"number","min":0,"max":_vm.settings.expiration_settings.expire_documents_in_days,"disabled":!_vm.settings.auto_remainders_settings.first_reminder_status,"size":"medium"},model:{value:(
                      _vm.settings.auto_remainders_settings
                        .send_first_reminder_in_days
                    ),callback:function ($$v) {_vm.$set(_vm.settings.auto_remainders_settings
                        , "send_first_reminder_in_days", $$v)},expression:"\n                      settings.auto_remainders_settings\n                        .send_first_reminder_in_days\n                    "}},[_c('template',{slot:"append"},[_c('i',{staticClass:"el-icon-date"}),_vm._v(" days")])],2)],1)],1),_c('div',{staticClass:"expiration-reminder-check"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('el-checkbox',{model:{value:(
                      _vm.settings.auto_remainders_settings.repeat_reminder_status
                    ),callback:function ($$v) {_vm.$set(_vm.settings.auto_remainders_settings, "repeat_reminder_status", $$v)},expression:"\n                      settings.auto_remainders_settings.repeat_reminder_status\n                    "}}),_c('div',{staticClass:"px-2 word-break-normal",staticStyle:{"width":"100px","padding-right":"0px !important"}},[_vm._v(" "+_vm._s(_vm.getIsMobile ? "Repeat" : "Repeat Reminder")+" ")]),_c('el-input-number',{attrs:{"disabled":!_vm.settings.auto_remainders_settings
                        .repeat_reminder_status,"min":1,"max":_vm.settings.expiration_settings.expire_documents_in_days},model:{value:(
                      _vm.settings.auto_remainders_settings.repeat_reminder
                    ),callback:function ($$v) {_vm.$set(_vm.settings.auto_remainders_settings, "repeat_reminder", $$v)},expression:"\n                      settings.auto_remainders_settings.repeat_reminder\n                    "}}),_c('div',{staticClass:"pl-1"},[_vm._v(" "+_vm._s(_vm.getIsMobile ? "Days" : "Until completed & expired")+" ")])],1)])])])],1)],1),_c('el-divider',{staticClass:"m-0"}),_c('el-form-item',{staticClass:"mb-0 pt-2 px-2 text-right"},[_c('el-button',{staticClass:"type-2",attrs:{"type":"danger"},on:{"click":_vm.checkUpdateSettings}},[_vm._v("Save")]),_c('el-button',{on:{"click":_vm.close}},[_vm._v("Cancel")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }